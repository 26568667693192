import React from 'react';
import TaskList from './Component/TaskList'; // Importe o componente TaskList

function App() {
  return (
    <div className="App">
      <TaskList /> {/* Use o componente TaskList aqui */}
    </div>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import './TaskList.css';

const TaskList = () => {
  const LOCAL_STORAGE_KEY = 'taskList';
  const recurringTasks = [
    { id: 1, text: 'Verificar lançamentos', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 2, text: 'Verificar e-mail da Amz Locadora ', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 3, text: 'Verificar e-mail da Lobato e Lima ', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 4, text: 'Verificar conciliação Locadora', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 5, text: 'Verificar conciliação Imob', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 6, text: 'Verificar conciliação Rede Amz', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 7, text: 'Verificar conciliação Fazenda', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 8, text: 'Faturamento de boleto', icon: '🔴', status: 'pending', completedTime: '' },
    { id: 9, text: 'Lançamentos no banco', icon: '🔴', status: 'pending', completedTime: '' },
  ];

  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
    const today = getToday();

    if (!storedTasks.length || storedTasks[0].date !== today) {
      const updatedTasks = recurringTasks.map(task => ({ ...task, date: today }));
      setTasks(updatedTasks);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedTasks));
    } else {
      const filteredTasks = storedTasks.filter(task => task.date === today && task.status !== 'deleted');
      setTasks(filteredTasks);
    }
  }, []);

  useEffect(() => {
    const date = new Date();
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    setCurrentDate(date.toLocaleDateString('pt-BR', options));
  }, []);

  const getToday = () => {
    const date = new Date();
    return date.toLocaleDateString();
  };

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const newId = tasks.length > 0 ? tasks[tasks.length - 1].id + 1 : 1;
      const updatedTasks = [
        ...tasks,
        {
          id: newId,
          text: newTask,
          icon: '🔴',
          status: 'pending',
          completedTime: '',
          date: getToday(),
        },
      ];
      setTasks(updatedTasks);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedTasks));
      setNewTask('');
    }
  };

  const handleTaskAction = (id) => {
    const taskIndex = tasks.findIndex((task) => task.id === id);
    const updatedTasks = [...tasks];

    if (updatedTasks[taskIndex].status === 'done') {
      updatedTasks[taskIndex].status = 'pending';
      updatedTasks[taskIndex].icon = '🔴';
      updatedTasks[taskIndex].completedTime = '';
    } else if (updatedTasks[taskIndex].status === 'pending') {
      updatedTasks[taskIndex].status = 'inProgress';
      updatedTasks[taskIndex].icon = '⌛';
      updatedTasks[taskIndex].completedTime = '';
    } else {
      updatedTasks[taskIndex].status = 'done';
      updatedTasks[taskIndex].icon = '✅';
      updatedTasks[taskIndex].completedTime = new Date().toLocaleTimeString();
    }

    setTasks(updatedTasks);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedTasks));
  };

  const handleDeleteTask = (id) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === id) {
        return { ...task, status: 'deleted' };
      }
      return task;
    });

    setTasks(updatedTasks.filter(task => task.status !== 'deleted'));
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedTasks.filter(task => task.status !== 'deleted')));
  };

  return (
    <div className="task-container">
      <h2>Lista de Tarefas do Dia</h2>
      <ul>
        {tasks.map((task) => (
          <li key={task.id} onClick={() => handleTaskAction(task.id)}>
            <span className="task-icon">{task.icon}</span>
            <span>{task.text}</span>
            {task.status === 'done' && <span className="completed-time"> - {task.completedTime}</span>}
            <button className="delete-button" onClick={(e) => { e.stopPropagation(); handleDeleteTask(task.id); }}>❌</button>
          </li>
        ))}
      </ul>

      <div className="task-input">
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          placeholder="Adicionar nova tarefa"
        />
        <button onClick={handleAddTask}>Adicionar</button>
      </div>

      <div className="footer">
        <p>{currentDate}</p>
      </div>
    </div>
  );
};

export default TaskList;
